@import "fonts";
@import "mixins";
@import "flexbox";
@import "flags/less/flag-icon";

//red color scheme
@primaryColor: #DF2424;
@primaryColorLight: #E86565;
@greenColor: #1ab394;
@greenColorLight: #1ab394;

.error-text{
    color: red;
}
.loginscreen {
    .main-logo {
        margin-bottom: 35px;
    }
    .middle-box {
        width: 360px;
    }
    .form-group{
        &.animated-appearance {
            height: 0;
            margin: 0;
            .transition(all, 500ms, 0ms);
            overflow: hidden;
            &.show {
                height: 76px;
                margin-bottom: 16px;
                overflow: visible;
            }
        }
    }
}
.help-bubble{
    visibility: hidden;
    position: absolute;
    left: 50%;
    bottom: 100%;
    width: 320px;
    background-color: white;
    min-height: 50px;
    margin-bottom: 36px;
    opacity: 0;
    margin-left: -160px;
    padding: 15px;
    z-index: 2;
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
    .transition(all, 700ms, 200ms);
    &:before{
        content: "";
        border: 10px solid transparent;
        border-top-color: white;
        position: absolute;
        left: 50%;
        top: 100%;
        margin-left: -10px;
    }
    &.show{
        visibility: visible;
        margin-bottom: 6px;
        opacity: 1;
    }
}
.error-page{
    .additional {
        display: none;
        padding: 20px;
        margin: 10px 5% 0;
        border: 1px solid #cccccc;
        background-color: #ffffff;
        position: absolute;
        text-align: left;
        width: 90%;
        white-space: nowrap;
        overflow: auto;
        p {
            font-size: 14px;
            line-height: 20px;
        }
        &.active {
            display: block;
        }
    }
    .additional-switch {
        text-decoration: none;
        margin-top: 40px;
        width: 100%;
        display: block;
        .material-icons{
            vertical-align: bottom;
            line-height: 21px;
        }
        .angle-up {
            display: none;
        }
        .angle-down {
            display: inline;
        }
        &.active {
            .angle-up {
                display: inline;
            }
            .angle-down {
                display: none;
            }
        }
    }
}

.change-language{
    display: none;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 26px;
    margin-left: 40px;
    width: auto;
    height: 30px;
    color: rgb(33, 33, 33);
    white-space: nowrap;
    font-family: 'Roboto',sans-serif;
    cursor: pointer;
    padding: 10px 0;
    font-size: 20px;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    @media screen and (max-width: 980px){
        top: 100%;
        left: 50%;
        margin-top: -60px;
        margin-left: -79px;
    }
    &.open{
        height: auto;
        background-color: rgba(207, 45, 44, 0.83);
        padding-top: 47px;
        box-shadow: 0 1px 4px rgba(0,0,0,0.4);
        color: white;
        @media screen and (max-width: 980px){
            background-color: rgba(207, 45, 44, 0.83);
        }
        .change-language__current-lang{
            &_static{
                display: none;
            }
            &_absolute{
                display: block;
            }
        }
        .change-language__list-of-languages{
            display: block;
        }
    }
    &__current-lang{
        &_static{
            padding: 0 25px;
            display: block;
        }
        &_absolute{
            display: none;
            position: absolute;
            left: 25px;
            top: 10px;
        }
    }
    &__list-of-languages{
        display: none;
    }
    &__list-row{
        display: block;
        color: white!important;
        line-height: 38px;
        text-align: left;
        padding: 0 35px 0 25px;
        &:hover{
            background-color: rgba(0,0,0,0.3);
        }
    }
    &__flag{
        top: 1px;
        width: 25px;
        height: 18px;
        margin-right: 6px;
    }
}

.t228.t228__positionabsolute{
    @media screen and (max-width: 980px){
        background-color: rgb(0, 0, 0)!important;
        padding-bottom: 50px;
        position: relative;
    }
    background-color: white!important;
}

.t-cover__arrow-wrapper{
    padding: 20px;
}

.t-menu__link-item{
    color: rgb(33, 33, 33);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-size: 13px;
}

.t228__list_item {
    padding-left: 10px;
    padding-right: 10px;
}

.text-style-subtitle{
    max-width: 960px;
    font-size: 1.229rem;
}

.mobile-menu{
    .menu-holder{
        .change-language{
            display: block;
            position: relative;
            top: 0;
            left: 0;
            height: 48px;
            margin: 0;
            color: white;
            pointer-events: all;
            font-size: 18px;
            &.open{
                height: auto;
            }
            a {
                display: block;
                padding: 0 35px 0 25px;
                font-size: 18px;
                line-height: 38px;
            }
        }
    }
}
@media (min-width:998px){
    .change-language{
        display: block;
    }
    body.adaptive [data-b-id='63'] .container .contact-holder{
        margin-top: 10px;
    }
}
@media (max-width: 570px) {
    [data-b-id="13"][data-id="68571"] .indent {
        padding-top: 0;
        padding-bottom: 100px;
    }
    .text-style-title-main {
        max-width: 320px;
        font-size: 1.55rem;
    }
    .text-style-subtitle {
        max-width: 280px;
        font-size: 0.7rem;
    }
    [data-b-id="13"] .btn-note {
        font-size: 0.9rem;
    }
    #rec28022404{
        padding-top: 50px!important;
        padding-bottom: 30px!important;
    }
}
@media (max-width: 997px){
    body.adaptive [data-b-id='63'] .container .contact-holder .btn-wrap {
        margin: 0!important;
    }
    body.adaptive [data-b-id='13'] .container .btn-wrap {
        margin-top: 20px;
    }
    .adaptive .b_block .container {
        width: 94%;
    }
}
@media (min-width: 997px) {
    .t-col {
        box-sizing: content-box;
    }
    [data-b-id="13"][data-id="68571"] .indent{
        padding-top: 0;
    }
}
@media (max-width: 570px) {
    body.adaptive .m_modal a.close {
        color: black!important;
    }
    body.adaptive [data-b-id='13'] .content {
        margin-top: 20px;
    }
    .text-style-subtitle{
        max-width: 100%;
    }
}
.header-menu{
    min-height: 108.59px;
    font-family: Roboto, Helvetica, Verdana, sans-serif !important;
    .container{
        .component-logo{
            background: url(../img/new-logo-whitebg-245.jpg) no-repeat;
            height: 68.6px;
            width: 122.5px;
            background-size: contain;
        }
        .menu-holder {
            .component-menu {
                height: 47px;
                ul {
                    padding: 10px 0!important;
                    li {
                        a {
                            color: rgb(33, 33, 33)!important;
                        }
                    }
                }
            }
        }
    }
}
.m_modal a.close {
    color: white!important;
}
.t-col {
    margin: 0;
}
.wellcome-screen{
    text-shadow: 0 0 20px rgba(0,0,0,0.2), 0 0 30px rgba(0,0,0,0.2);
    .btn-wrap{
        text-shadow: none;
    }
}
.b_block.b_68571{
    height: 90vh;
    .container-fluid{
        height: 100%;
    }
}
.mobile-menu .menu-content{
    height: calc(~"100% - 90px");
    z-index: 4;
}
.block-contact-info{
    position: relative;
    top: -17px;
    .change-language{
        top: 100%;
        margin-top: -8px;
        left: 50%;
        margin-left: -80px;
    }
    .contact {
        min-width: 170px;
    }
}
.modal-content{
    .success-text{
        display: none;
        padding-top: 20px;
        color: green;
    }
    &.is_success{
        .success-text{
            display: block;
        }
        .component-form{
            display: none;
        }
    }
}
[data-b-id="63"] .container .contact-holder.inline .contact-desc {
    text-align: center;
}
@media screen and (orientation:landscape) and (max-width:767px) {
    .header-menu {
        height: 110px;
    }
    .block-contact-info {
        top: -100px;
    }
    body.adaptive [data-b-id="63"] .container .contact-holder .btn-wrap{
        top: -90px;
        position: relative;
    }
    [data-b-id="13"][data-id="68571"] .indent {
        padding-top: 0;
        padding-bottom: 100px;
    }
    .text-style-title-main {
        max-width: 320px;
        font-size: 1.55rem;
    }
    .text-style-subtitle {
        max-width: 280px;
        font-size: 0.7rem;
    }
    [data-b-id="13"] .btn-note {
        font-size: 0.9rem;
    }
    #rec28022404{
        padding-top: 50px!important;
        padding-bottom: 30px!important;
    }
    .text-style-subtitle{
        max-width: 100%;
    }
    .hide-on-landscape{
        display: none;
    }

}

.component-menu ul{
    .flex-flow(row nowrap);
    white-space: nowrap;
}

//footer
#rec28022436{
    a{
        color: white;
        text-decoration: none;
        &:hover{
            color: white;
            text-decoration: underline;
        }
    }
}


